import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
    selector: 'app-musiciens',
    templateUrl: './musiciens.component.html',
    styleUrls: ['./musiciens.component.scss'],
    standalone: false
})
export class MusiciensComponent implements OnInit {
  constructor(private titleService: Title, public sidenavService: SidenavService) {
    this.titleService.setTitle('Les musiciens');
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.ARTISTES);
  }
}
